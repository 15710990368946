<template>
  <div>
    <div class="search flex-item_center">
      <div class="searchItem flex-item_center">
        <img class="searchItem-icon" src="../assets/imgs/search.png" alt="" />
        <input type="text" class="" placeholder="搜索标题" />
      </div>
    </div>

    <div class="script">
      <!-- <div class="flex flex-center wrap">
        <div
          class="scriptItem"
          v-for="(item, index) in [0, 1, 2, 3, 4, 5, 6]"
          :key="index"
        >
          常见问题
        </div>
      </div> -->

      <!-- <div class="script-title">问题标签</div>
      <div class="flex flex-center wrap">
        <div
          class="questionItem"
          v-for="(item, index) in question"
          :key="index"
        >
          {{ item }}
        </div>
      </div> -->

      <!-- 文字 -->
      <div
        class="text rel flex column flex-center"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="textItem-title">{{ item.title }}</div>
        <div class="textItem">
          {{ item.content }}
        </div>
        <img
          src="../assets/imgs/share.png"
          class="abs article-icon"
          @click="send(item)"
          alt=""
          v-if="toolbar"
        />
        <div class="remind-bottom flex flex-center" v-else>
          <div
            class="flex-item_center remind-bottom_item"
            style="border-right:1px solid #ededf7;"
            @click="share(item, 1, 'image')"
          >
            <img src="../assets/imgs/share.png" alt="" />
            <div>个人</div>
          </div>
          <div
            class="flex-item_center remind-bottom_item"
            @click="share(item, 2, 'image')"
          >
            <img src="../assets/imgs/share.png" alt="" />
            <div>群聊</div>
          </div>
        </div>
      </div>
      <!-- <div class="script-underline"></div>
      <div class="srciptItem flex flex-center column">
        <div class="flex flex-center">
          <div class="flex flex-center srciptItem-all">
            <img class="srciptItem-img" src="../assets/imgs/task.png" alt="" />
            <div>
              <div>如何保养电瓶车如何保养电瓶车</div>
              <div>简介简介简介简介简介简介简介简介</div>
            </div>
          </div>
          <img class="srciptItem-icon" src="../assets/imgs/share.png" alt="" />
        </div>

        <div class="srciptItem-big flex flex-center">
          <img
            class="srciptItem-big_icon"
            src="../assets/imgs/homeBg.png"
            alt=""
          />
          <img class="srciptItem-icon" src="../assets/imgs/share.png" alt="" />
        </div>

        <div class="srciptItem-btn flex-item_center">发送这组</div>
      </div> -->
    </div>

    <img
      src="../assets/imgs/addContent.png"
      @click="router()"
      class="add fixed"
      alt=""
    />
    <div class="while-p"></div>
    <tab :current="1" :isShow="6"></tab>
  </div>
</template>

<script>
import * as request from "../api/data";
import _ from "lodash";
import { Toast } from "vant";
import tab from "@/components/tab.vue";

export default {
  components: { tab },
  data() {
    return {
      question: [
        "车型问题",
        "问题过长问题过长",
        "问题超级长问题超级长问题超级长问题超级长",
      ],
      list: [],
      page: 1,
      toolbar: false,
    };
  },
  async mounted() {
    let that = this;
    this.getList();

    let res = await request._getConfig({
      url: location.href.split("#")[0],
    });
    // console.log("获取配置", res);
    if (_.toInteger(res.code) == 1) {
      console.log("进来额");
      let result = res.data;
      that.$wx.config({
        beta: true,
        debug: false, // 关闭调试模式
        appId: result.corpid,
        timestamp: result.timestamp,
        nonceStr: result.nonceStr,
        signature: result.signature,
        jsApiList: [
          "sendChatMessage",
          "shareToExternalContact",
          "getContext",
          "shareToExternalChat",
        ],
      });
      that.$wx.ready(function() {
        that.$wx.agentConfig({
          corpid: result.corpid,
          agentid: result.agentid,
          timestamp: result.timestamp,
          nonceStr: result.nonceStr,
          signature: result.signature2,
          jsApiList: [
            "sendChatMessage",
            "shareToExternalContact",
            "getContext",
            "shareToExternalChat",
          ],
          success: function(res) {
            console.log("res", res);
            // 回调
            //判断场景
            that.$wx.invoke("getContext", {}, function(res) {
              console.log("getContext res", res);
              if (res.err_msg == "getContext:ok") {
                var entry = res.entry; //返回进入H5页面的入口类型，目前有normal、contact_profile、single_chat_tools、group_chat_tools、chat_attachment
                console.log("我的场景", entry);
                if (
                  entry == "chat_attachment" ||
                  entry == "single_chat_tools" ||
                  entry == "group_chat_tools"
                ) {
                  that.toolbar = true;
                }
              } else {
                //错误处理
              }
            });
          },
          fail: function(res) {
            console.log("that.$wx.agentConfig err", res);
            if (res.errMsg.indexOf("function not exist") > -1) {
              alert("版本过低请升级");
            }
          },
        });
      });
    }
  },
  methods: {
    send(item, type) {
      console.log("item", item);
      wx.invoke(
        "sendChatMessage",
        {
          msgtype: "text", //消息类型，必填
          enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
          text: {
            content: item.content, //文本内容
          },
        },
        function(res) {
          if (res.err_msg == "sendChatMessage:ok") {
            //发送成功
          }
        }
      );
      // wx.invoke(
      //   "shareToExternalContact",
      //   {
      //     text: {
      //       content: "", // 文本内容
      //     },
      //     attachments: [
      //       {
      //         msgtype: "image", // 消息类型，必填
      //         image: {
      //           mediaid: item.media_id, //图片的素材id
      //         },
      //       },
      //     ],
      //   },
      //   function(res) {
      //     if (res.err_msg == "shareToExternalContact:ok") {
      //     }
      //   }
      // );
    },
    //分享
    share(item, type, mold) {
      let that = this;
      // 参数type == 1个人 ==2发送群
      if (type == 1) {
        that.$wx.invoke(
          "shareToExternalContact",
          {
            text: {
              content: item.content, // 文本内容
            },
            attachments: [],
          },
          function(res) {
            if (res.err_msg == "shareToExternalContact:ok") {
            }
          }
        );
      } else {
        that.$wx.invoke(
          "shareToExternalChat",
          {
            text: {
              content: item.content, // 文本内容
            },
            attachments: [],
          },
          function(res) {
            if (res.err_msg == "shareToExternalChat:ok") {
            }
          }
        );
      }
    },

    router() {
      this.$router.push("/AddScript");
    },
    async getList() {
      let res = await request._speechcraftList({
        userid: localStorage.getItem("userid"),
        page: this.page,
      });
      console.log("话术 res", res);
      if (_.toInteger(res.code) === 1) {
        this.list = res.data.data;
      }
    },
  },
};
</script>

<style scoped>
.search {
  width: 100%;
  height: 131px;
  border-top: 1px solid #d6e5ee;
  background: #fff;
}

.searchItem {
  width: 690px;
  height: 70px;
  background: #f4f5f7;
  border-radius: 35px 35px 35px 35px;
  font-size: 26px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #66728f;
}

.search input {
  outline: none;
  border: none;
  width: 200px;
  background: #f4f5f7;
}

.script {
  width: 100%;
  /*margin: 24px auto 0;
  padding: 43px 30px;
  background: #ffffff;*/
  border-radius: 24px 24px 24px 24px;
}

.scriptItem {
  padding: 7px 34px;
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #101f48;
  background: #f1f3ff;
  border-radius: 4px;
  margin: 0 18px 18px 0;
}

.script-title {
  padding: 48px 0 27px 0;
}

.questionItem {
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #0558f4;
  padding: 7px 37px;
  background: #edf3ff;
  margin: 0 16px 18px 0;
}

.script-underline {
  width: 690px;
  height: 1px;
  background: #d6e5ee;
}

.srciptItem {
  padding: 34px 0 54px 0;
}

.srciptItem-img {
  width: 52px;
  height: 52px;
  margin: 0 13px 0 16px;
}

.srciptItem-icon {
  width: 60px;
  height: 60px;
  margin-left: 21px;
}

.srciptItem-all {
  width: 558px;
  height: 120px;
  background: #f4f5f7;
  border-radius: 16px 16px 16px 16px;
}

.srciptItem-big {
  width: 630px;
  background: #fff;
  margin: 26px auto 0;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.srciptItem-big_icon {
  width: 558px;
  height: 314px;
  border-radius: 16px;
}

.srciptItem-btn {
  width: 630px;
  height: 80px;
  background: #0558f4;
  border-radius: 16px 16px 16px 16px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  margin: 62px auto 0;
}

.searchItem-icon {
  margin-right: 10px;
  width: 23px;
  height: 24px;
}

.while-p {
  height: 200px;
}

.add {
  width: 80px;
  height: 80px;
  right: 60px;
  bottom: 160px;
}

.text {
  width: 690px;
  height: auto;
  background: #ffffff;
  margin: 16px auto;
  border-radius: 24px 24px 24px 24px;
}

.textItem {
  font-size: 32px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
  margin: 10px auto 0;
  width: 576px;
  max-height: 135px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.article-icon {
  width: 46px;
  height: 46px;
  right: 30px;
  bottom: 30px;
}

.textItem-title {
  margin-top: 20px;
  font-size: 28px;
  color: #101f48;
  width: 576px;
  font-weight: bold;
}

.remind {
  width: 690px;
  margin: 24px auto;
  background: #fff;
  border-radius: 24px;
  padding: 30px 0 0 0;
}

.remind-img {
  width: 100%;
  height: auto;
  border-radius: 24px;
}

.remind-bottom {
  margin-top: 46px;
  height: 81px;
  width: 100%;

  border-top: 1px solid #ededf7;
}

.remind-bottom_item {
  width: 50%;
  height: 81px;
  font-size: 28px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #0558f4;
}

.remind-bottom_item img {
  width: 50px;
  height: 50px;
  margin-right: 16px;
}
</style>
